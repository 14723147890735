export const STORE_OVERVIEW_PREFERENCES = {
  REIMBURSEMENT: 'reimbursement',
  SHIPPING: 'shipping',
  VAT: 'vat',
  PPC: 'ppc',
  DSP: 'dsp',
};

export const COKKIE_GET_USER_PREFERENCES = {
  ACTIVE_MAIN_PREFERENCES_KEY_LIST: 'activeMainPreferenceKeyList',
  IS_DEFAULT_COLLAPSE_PREFERENCES_GROUP: 'isDefaultCollapsePreferencesGroup',
};
