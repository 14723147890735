import { STORE_OVERVIEW_PREFERENCES } from '@/constants';
import { getCookie } from '@/helper';
import { COKKIE_GET_USER_PREFERENCES } from '@/constants';

export default {
  acosRoasSelect: 'ACoS',
  tacosTacopTprasSelect: 'TACoS',
  advertisingSalesAdvertisingOrdersSelect: 'Advertising Sales',
  organicSalesOrganicOrdersSelect: 'Organic Sales',
  totalSalesTotalOrdersSelect: 'Total Sales',
  activeMainPreferenceKeyList: getCookie(
    COKKIE_GET_USER_PREFERENCES.ACTIVE_MAIN_PREFERENCES_KEY_LIST
  )
    ? JSON.parse(
        getCookie(COKKIE_GET_USER_PREFERENCES.ACTIVE_MAIN_PREFERENCES_KEY_LIST)
      )
    : [],
  activeAdvPreferenceKeyList: JSON.parse(
    localStorage.getItem('activeAdvPreferenceKeyList')
  ) || [STORE_OVERVIEW_PREFERENCES.PPC],
};
