"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userUnvisibleViewColumns = exports.userSubscriptionDeleteTypes = exports.userSubscriptionEditFields = exports.userContactEditFields = exports.userAccountEditFields = exports.userStoreEditFields = exports.userSubscriptionEditTypes = exports.adminPanelAccountPermissions = exports.adminDashboardOnlyAccountPermissions = exports.routesDoesNotRequireSellerStore = void 0;
/**
 * @description List of pages that does not require seller store (array contains route names)
 * @type {string[]}
 * @example
 * ['VendorManagement']
 */
exports.routesDoesNotRequireSellerStore = [
    'VendorManagement',
    'admin-dashboard',
    'admin-dashboard/daily-task',
    'admin-dashboard/details',
    'admin-dashboard/details/infoDetails',
    'admin-dashboard/pages',
    'link-account',
];
exports.adminDashboardOnlyAccountPermissions = [
    'finance_admin_user',
    'reimbursement_user',
    'customer_success_user',
    'sales_account_manager',
    'account_manager',
    'sales_development_representative',
    'crm_super_admin',
    'research_and_development_user',
];
exports.adminPanelAccountPermissions = {
    dailyTask: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    accountTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        search: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        filter: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        sorting: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        export: ['finance_admin_user', 'crm_super_admin'],
        add: [
            'finance_admin_user',
            'sales_account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'customer_success_user',
        ],
        rowAction: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    contactTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        search: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        rowAction: [
            'finance_admin_user',
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    storeTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        search: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        filter: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        rowAction: [
            'finance_admin_user',
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
    },
    mrrTable: {
        view: ['finance_admin_user', 'crm_super_admin'],
        export: ['finance_admin_user', 'crm_super_admin'],
    },
    actionTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        export: ['finance_admin_user', 'crm_super_admin'],
    },
    invoiceTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        search: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        filter: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        rowAction: [
            'finance_admin_user',
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        delete: ['finance_admin_user', 'crm_super_admin'],
    },
    opportunityTable: {
        view: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        search: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        filter: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        rowAction: [
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    eventTable: {
        view: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        search: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        filter: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        rowAction: [
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
    },
    partnerTable: {
        view: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        search: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        filter: [
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        rowAction: [
            'customer_success_user',
            'reimbursement_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    manageStoreTable: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        search: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        filter: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        sorting: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        export: [
            'finance_admin_user',
            'crm_super_admin',
            'research_and_development_user',
        ],
        add: [
            'finance_admin_user',
            'sales_account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        rowAction: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
    },
    accountDetails: {
        view: [
            'finance_admin_user',
            'reimbursement_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        editForm: [
            'finance_admin_user',
            'sales_account_manager',
            'customer_success_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        mergeAccount: [
            'finance_admin_user',
            'sales_account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        changeAccountOwner: [
            'finance_admin_user',
            'sales_account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        subscriptionTotalMrr: [
            'finance_admin_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        addSourceDetailType: [
            'finance_admin_user',
            'sales_account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        contactDetailsTable: {
            view: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            search: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            add: [
                'finance_admin_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
        },
        storeDetailsTable: {
            view: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            search: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            edit: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
        },
        subscriptionDetailsTable: {
            view: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            filter: [
                'finance_admin_user',
                'reimbursement_user',
                'customer_success_user',
                'sales_account_manager',
                'account_manager',
                'sales_development_representative',
                'crm_super_admin',
            ],
            export: ['finance_admin_user', 'crm_super_admin'],
            subscriptionLogs: {
                view: [
                    'finance_admin_user',
                    'reimbursement_user',
                    'customer_success_user',
                    'crm_super_admin',
                ],
                deactive: ['finance_admin_user', 'crm_super_admin'],
                export: ['finance_admin_user', 'crm_super_admin'],
            },
            add: ['finance_admin_user', 'crm_super_admin'],
            edit: ['finance_admin_user', 'crm_super_admin', 'reimbursement_user'],
            delete: ['finance_admin_user', 'crm_super_admin'],
            stripeSubscriptionId: {
                view: {
                    edit: ['finance_admin_user', 'crm_super_admin'],
                    add: [
                        'finance_admin_user',
                        'crm_super_admin',
                        'customer_success_user',
                        'reimbursement_user',
                    ],
                },
            },
            syncStripeButton: {
                permission: [
                    'crm_super_admin',
                    'finance_admin_user',
                    'reimbursement_user',
                ],
            },
            oneTimePaymentButton: ['finance_admin_user', 'crm_super_admin'],
        },
        refAccountDetailsTable: {
            add: ['finance_admin_user', 'customer_success_user', 'crm_super_admin'],
            delete: [
                'finance_admin_user',
                'customer_success_user',
                'crm_super_admin',
            ],
        },
    },
    contactDetails: {
        view: [
            'finance_admin_user',
            'sales_account_manager',
            'customer_success_user',
            'reimbursement_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        add: [
            'finance_admin_user',
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        editForm: [
            'finance_admin_user',
            'sales_account_manager',
            'customer_success_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    storeDetails: {
        view: [
            'finance_admin_user',
            'sales_account_manager',
            'customer_success_user',
            'reimbursement_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'research_and_development_user',
        ],
        editForm: [
            'finance_admin_user',
            'sales_account_manager',
            'customer_success_user',
            'reimbursement_user',
            'sales_development_representative',
            'crm_super_admin',
        ],
        disconnectStore: ['crm_super_admin'],
    },
    opportunityDetails: {
        view: [
            'sales_account_manager',
            'customer_success_user',
            'reimbursement_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        add: [
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
        editForm: [
            'sales_account_manager',
            'customer_success_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
        ],
    },
    eventDetails: {
        view: [
            'sales_account_manager',
            'customer_success_user',
            'reimbursement_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        add: [
            'customer_success_user',
            'sales_account_manager',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
        editForm: [
            'sales_account_manager',
            'customer_success_user',
            'account_manager',
            'sales_development_representative',
            'crm_super_admin',
            'finance_admin_user',
        ],
    },
};
exports.userSubscriptionEditTypes = {
    reimbursement_user: [2, 5],
    finance_admin_user: [1, 2, 3, 4, 5],
    crm_super_admin: [1, 2, 3, 4, 5],
};
exports.userStoreEditFields = {
    reimbursement_user: ['reimbursementStatus'],
    finance_admin_user: [
        'storeType',
        'reimbursementStatus',
        'accountName',
        'note',
        'trialEndTime',
    ],
    crm_super_admin: [
        'storeType',
        'reimbursementStatus',
        'accountName',
        'note',
        'trialEndTime',
    ],
    customer_success_user: [
        'storeType',
        'reimbursementStatus',
        'note',
        'trialEndTime',
    ],
    sales_account_manager: [
        'storeType',
        'reimbursementStatus',
        'accountName',
        'note',
        'trialEndTime',
    ],
    sales_development_representative: [
        'storeType',
        'reimbursementStatus',
        'accountName',
        'note',
        'trialEndTime',
    ],
};
exports.userAccountEditFields = {
    //if necessary you can add permission with parameters
    account_manager: ['ppcSpecialist', 'brandManager'],
};
exports.userContactEditFields = {
    //if necessary you can add permission with parameters
    account_manager: [
        'firstName',
        'lastName',
        'phoneNumber',
        'alternativePhoneNumber',
        'contactType',
        'email',
        'alternativeEmail',
        'linkedinProfile',
        'description',
        'noContact',
        'phoneNumberTimezone',
        'phoneNumberExtension',
    ],
};
exports.userSubscriptionEditFields = {
    finance_admin_user: [
        'subscriptionType',
        'packageType',
        'subscriptionPrice',
        'subscriptionStatus',
        'subscriptionStartDate',
        'subscriptionEndDate',
        'isAnnual',
        'commissionRate',
        'freeCredit',
        'initialTerm',
        'subsequentTerm',
        'retainerFee',
        'cancellationType',
        'cancellationReason',
        'subscriptionNote',
        'paymentMethods',
        'statusEndDate',
        'cancellationNoticePeriodMonth',
        'isProcessingFee',
    ],
    crm_super_admin: [
        'subscriptionType',
        'packageType',
        'subscriptionPrice',
        'subscriptionStatus',
        'subscriptionStartDate',
        'subscriptionEndDate',
        'isAnnual',
        'commissionRate',
        'freeCredit',
        'initialTerm',
        'subsequentTerm',
        'retainerFee',
        'cancellationType',
        'cancellationReason',
        'subscriptionNote',
        'paymentMethods',
        'statusEndDate',
        'cancellationNoticePeriodMonth',
        'isProcessingFee',
    ],
    reimbursement_user: ['commissionRate', 'subscriptionStatus'],
};
exports.userSubscriptionDeleteTypes = {
    finance_admin_user: [1, 2, 3, 4, 5],
    crm_super_admin: [1, 2, 3, 4, 5],
};
exports.userUnvisibleViewColumns = {
    reimbursement_user: {
        accountTable: ['subscriptionTotalMrr'],
    },
    customer_success_user: {
        accountTable: ['subscriptionTotalMrr'],
        subscriptionDetailsTable: [''],
        subscriptionLogsTable: ['subscriptionPrice'],
    },
};
