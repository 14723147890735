import marketplacesCurrency from '@/json/marketplacesCurrency';
import storeX from '../store/store';
import _ from 'lodash';
import localForage from 'localforage';
import marketPlaceList from '@/json/marketplaceList';
import moment from 'moment';

const RECEIVE_STATUS = {
  FULLY: { key: 1, label: 'Fully Received', color: '#216E4E' },
  PARTIALLY: {
    key: 2,
    label: 'Partially Received',
    color: '#974F0C',
  },
  NONE: { key: 3, label: 'Non-Received', color: '#AE2A19' },
  OVERLY: { key: 4, label: 'Overly Received', color: '#7b68d9' },
};

const getTimeZone = async () => {
  let storeInstance = await storeX();
  const store = localStorage.getItem('store');
  const marketplace = localStorage.getItem('marketplace');
  const userInfo =
    storeInstance.getters['userInfo/uInfo'] ||
    (await localForage.getItem('userInfo'));
  const storeArray = userInfo.user.store;
  let marketplaceCode = null;
  let timeZone = 'America/Los_Angeles';

  if (storeArray.length > 0) {
    for (let i in storeArray) {
      if (
        store === storeArray[i].storeId &&
        marketplace === storeArray[i].marketplaceName
      ) {
        marketplaceCode = storeArray[i].marketplaceCode;
      }
    }
  }
  for (let i in marketplacesCurrency) {
    if (marketplacesCurrency[i].default_country_code == marketplaceCode) {
      timeZone = marketplacesCurrency[i].time_zone;
    }
  }
  return timeZone;
};

/**
 * Checks the date is valid
 * @param {Date} date
 * @returns {boolean}
 */
const isValidDate = (date) => {
  // Check existance of date parameter
  if (!date) return false;

  // Check data type of parameter
  if (typeof date !== 'object') return false;

  // Check getTime function
  if (typeof date.getTime !== 'function') return false;

  return !isNaN(date.getTime());
};

/**
 * Get date on mm/dd/yyyy format
 * returns null if the parameter is not a date
 * @param {Date} date
 * @returns {String|null}
 */
export const getFormattedDate = (date, shouldShowTime = false) => {
  if (!isValidDate(date)) return null;

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: undefined,
    minute: undefined,
  };

  if (shouldShowTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getItemStatusByEnum = (statusEnum) => {
  for (const status in RECEIVE_STATUS) {
    if (RECEIVE_STATUS[status].key === statusEnum)
      return RECEIVE_STATUS[status];
  }

  return null;
};

export const changeTimeZone = async (operationTime) => {
  var timeZone = await getTimeZone();
  var options = {
    hourCycle: 'h23',
    timeZone: timeZone,
  };
  var gmtTime = new Date(operationTime);
  var pstTime = gmtTime.toLocaleString('en-US', options);
  pstTime = pstTime.replace(', ', 'T');
  pstTime = pstTime.replaceAll('/', '-');
  let dayTime = pstTime.split('T')[0].split('-')[0];
  if (pstTime.split('T')[0].split('-')[0].length == 1) {
    dayTime = '0' + pstTime.split('T')[0].split('-')[0];
  }
  let mounthTime = pstTime.split('T')[0].split('-')[1];
  if (parseFloat(pstTime.split('T')[0].split('-')[1]) < 10) {
    mounthTime = '0' + pstTime.split('T')[0].split('-')[1];
  }
  pstTime =
    dayTime +
    '-' +
    mounthTime +
    '-' +
    pstTime.split('T')[0].split('-')[2] +
    ' ' +
    pstTime.split('T')[1];
  return pstTime;
};

export const thousandSeprator = (amount) => {
  if (
    amount !== '' ||
    amount !== undefined ||
    amount !== 0 ||
    amount !== '0' ||
    amount !== null
  ) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return amount;
  }
};

export const fetchVersionInfo = () => {
  const randomh = new Date().getTime();

  return fetch('../version.json?t=' + randomh + '')
    .then((response) => response.json())
    .then((data) => data && data[0]);
};

export const setInitialStoreValues = async (i) => {
  let storeInstance = await storeX();
  const currentUserInfo = storeInstance.getters['userInfo/uInfo'];
  if (currentUserInfo.user.store.length === 0) return;
  const currentStore = currentUserInfo.user.store[i];

  const propertyList = [
    'uploadUUID',
    'downloadUUID',
    'minMaxUUID',
    'downloadUUIDType',
    'downloadfileName',
    'totalSkuNumber',
    'exportUUID',
    'exportReplenishmentUUID',
    'exportFileUrl',
  ];

  propertyList.forEach((property) => {
    if (!_.has(currentStore, property)) {
      currentStore[property] = null;
    }
  });

  localStorage.setItem(
    'packageInformation',
    JSON.stringify(currentUserInfo.user.packageInformation)
  );
  storeInstance.dispatch('updateSelectedStore', currentStore);
};

export const thousandFormatter = (
  number,
  currency = null,
  decimal = true,
  thousandSeparator = ',',
  hasPercentage = false
) => {
  if (notAssigned(number) || Number.isNaN(number)) return '-';

  // Sayının negatif olup olmadığını kontrol et ve mutlak değerini al
  const isNegative = number < 0;
  number = Math.abs(number);

  if (decimal) {
    number = number.toFixed(2);
  } else {
    number = number.toString();
  }

  const pricePattern = /(\d)(?=(\d{3})+(?!\d))/g;
  const repl = `$1${thousandSeparator}`;
  let percentage = hasPercentage ? '%' : '';
  let intPart = number.split('.')[0];
  let intPartFormat = intPart.toString().replace(pricePattern, repl);
  let value2Array = number.split('.');
  let formattedNumber;

  if (currency != null && decimal) {
    formattedNumber = currency + intPartFormat + '.' + value2Array[1];
  } else if (decimal || hasPercentage) {
    formattedNumber = intPartFormat + '.' + value2Array[1] + percentage;
  } else {
    formattedNumber = intPartFormat;
  }

  // Eğer sayı negatifse, başına '-' işaretini ekle
  return isNegative ? '-' + formattedNumber : formattedNumber;
};

export const setCookie = (cname, cvalue) => {
  document.cookie = cname + '=' + cvalue;
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const convertToCSV = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ',';

      line += String(array[i][index]).replaceAll(',', '');
    }

    str += line + '\r\n';
  }

  return str;
};

export const exportToCSV = (csv, fileTitle) => {
  var exportedFileName = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const convertToExcel = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ',';

      line += String(array[i][index]).replace(',', '');
    }

    str += line + '\r\n';
  }

  return str;
};

export const exportToExcel = (csv, fileTitle) => {
  var exportedFileName = fileTitle + '.xlsx' || 'export.xlsx';

  var blob = new Blob([csv], { type: 'text/xlsx;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
export const isCurrentUserAdvertisingAdmin = async () => {
  let storeInstance = await storeX();
  const userInfo =
    storeInstance.getters['userInfo/uInfo'] ||
    (await localForage.getItem('userInfo'));

  if (userInfo && _.has(userInfo, ['user', 'userPermissionList'])) {
    return userInfo.user.userPermissionList.some(
      (permission) => permission.PermissionCode === 'advertising_admin_user'
    );
  }

  return false;
};

export const getMarketplaceID = async () => {
  const storeInstance = await storeX();
  const selectedStore = storeInstance.getters['selectedStore'];
  const marketPlaceName = selectedStore.marketplaceName;
  const marketPlaceCode = selectedStore.marketplaceCode;

  return marketPlaceList.find(
    (marketPlaceItem) =>
      marketPlaceItem.name === marketPlaceName &&
      marketPlaceItem.country_code === marketPlaceCode
  ).marketplace_id;
};

export const getMarketplaceIDWithName = async (marketplaceName) => {
  return marketPlaceList.find(
    (marketPlaceItem) => marketPlaceItem.name === marketplaceName
  ).marketplace_id;
};

export const getTimeZoneInfo = async (marketplace) => {
  return marketplacesCurrency.find(
    (marketPlaceItem) => marketPlaceItem.marketplace_id === marketplace
  ).time_zone_diff;
};
export const animateProp = (obj, to, opts, after) => {
  Object.keys(to).forEach((key) => {
    if (opts.duration === 0) {
      obj[key] = to[key];
      return;
    }
    const from = obj[key];
    const toVal = to[key];
    const diff = toVal - from;
    const startTime = Date.now();
    const step = () => {
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = elapsed / opts.duration;
      obj[key] = from + diff * progress;
      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        obj[key] = toVal;
        if (after) {
          after();
        }
      }
    };
    requestAnimationFrame(step);
  });
};
/**
 * Extracts day, month and year from given string and
 * calls parsedDateStringsToDate function
 * @param {String} arg
 * @returns {Function} parsedDateStringsToDate
 */
const nonSlashedStringToDate = (arg) => {
  if (arg.length !== 8) return null;

  const month = arg.substring(0, 2);
  const day = arg.substring(2, 4);
  const year = arg.substring(4);

  return parsedDateStringsToDate({ year, month, day });
};

/**
 * Creates a new date based on parameters
 * @param {Object} obj
 * @param {String} obj.day
 * @param {String} obj.month
 * @param {String} obj.year
 * @returns {Date|null} returns date or null if date is invalid
 */
const parsedDateStringsToDate = ({ day, month, year }) => {
  // Check parameters lenght
  if (
    day.length > 2 ||
    day.length === 0 ||
    month.length > 2 ||
    month.length === 0 ||
    year.length < 4 ||
    year.length === 0
  )
    return null;

  const date = new Date(
    `${year}-${month.length < 2 ? `0${month}` : month}-${
      day.length < 2 ? `0${day}` : day
    }`
  );

  return isValidDate(date) ? date : null;
};

/**
 * Converts string to date according how it was written
 * @param {String} value
 * @returns
 */
export const stringToDate = (value) => {
  let result = null;

  if (typeof value !== 'string') return result;

  const args = value.split('/');

  switch (args.length) {
    case 1:
      result = nonSlashedStringToDate(args[0]);
      break;
    case 3:
      result = parsedDateStringsToDate({
        year: args[2],
        month: args[0],
        day: args[1],
      });
      break;
  }

  return result;
};

/**
 * Set current hours to the date
 * @param {Date} date
 * @returns {Date}
 */
export const setCurrentHourToDate = (date) => {
  const currentDate = new Date();
  date.setHours(currentDate.getHours(), currentDate.getMinutes());

  return date;
};

/**
 * Finds the item on list
 * @param {any} value identical value to be searched on list
 * @param {string} key identical property name of list
 * @param {Object[]} list the array to be searched
 * @returns {Object|null} result
 */
export const findSelectedItem = (value, key, list) => {
  return (
    list.find((item) => {
      let data1 = item[key];
      let data2 = value;

      // Check types
      if (typeof data1 !== typeof data2) return null;

      // Set texts to lower case if types are string
      if (typeof data === 'string') {
        data1 = data1.toLowerCase();
        data2 = data2.toLowerCase();
      }

      return data1 === data2;
    }) || null
  );
};

/**
 * Gets color class according to fba status and expiration date
 *
 * **NOTE**, since we re no longer getting isFBA info from backend,
 * isFba parameter is set *true* as default
 * @param {Date|null} exiprationDate
 * @param {Boolean|undefined} isFba
 * @returns
 */
export const expirationDateColor = (exiprationDate, isFba = true) => {
  if (!exiprationDate) return '';

  const today = new Date();

  if (exiprationDate.getTime() <= today.getTime()) return 'text-danger';

  if (!isFba) return '';

  if (calculateDayDifference(exiprationDate, today) < 105)
    return 'text-warning';

  return '';
};

/**
 * Calculates difference of days between two dates
 * @param {Date} date1
 * @param {Date} date2
 * @returns {boolean}
 */
export const calculateDayDifference = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return null;

  return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
};

export const downloadFile = (file, newTab = false) => {
  let link = document.createElement('a');
  link.href = file;
  if (newTab) {
    link.target = '_blank'; // Yeni sekme
  }
  link.click();
};
/**
 * Return array that contains unique primitives
 * @param {array} targetArray
 * @returns {array} Filtered array
 */
export const removeDuplicatePrimitivesFromArray = (targetArray) => {
  return targetArray.filter(
    (value, index, self) => self.indexOf(value) === index
  );
};

/**
 * Return array that contains unique objects
 * @param {array} targetArray
 * @param {string} compareKey
 * @returns {array} Filtered array
 */
export const removeDuplicateObjectsFromArray = (targetArray, compareKey) => {
  return targetArray.filter(
    (value, index, self) =>
      index ===
      self.findIndex((arrayItem) => arrayItem[compareKey] === value[compareKey])
  );
};

export const shortenString = (str, maxLen = 50, extra = '...') => {
  if (!str) return '';
  if (str.length <= maxLen) return str;

  return str.substring(0, maxLen) + extra;
};

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const kebabCaseToTitleCase = (str) => {
  return str
    .toLowerCase()
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const uuid = () => {
  if (!window.crypto)
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  return window.crypto.randomUUID();
};

export const getChangedValuesFromObject = async (oldObject, newObject) => {
  const changedValues = [];

  for (let key in oldObject) {
    if (oldObject.hasOwnProperty(key)) {
      const value = oldObject[key];

      if (typeof value === 'object' && !Array.isArray(value)) {
        // İç içe objeleri kontrol etmek için recursive olarak çağırıyoruz
        const insideChanges = await getChangedValuesFromObject(value);

        if (insideChanges.length > 0) {
          changedValues.push({
            [key]: insideChanges,
          });
        }
      } else if (value !== newObject[key]) {
        changedValues.push({
          key: key,
          value: value,
        });
      }
    }
  }

  return changedValues;
};

export const assignedWithEmptyString = (value) => {
  return (
    value !== null &&
    value !== 'undefined' &&
    value !== undefined &&
    value !== 'null' &&
    value !== ''
  );
};

export const notAssigned = (value) => {
  return (
    value === null ||
    value === 'undefined' ||
    value === undefined ||
    value === 'null'
  );
};

export const assigned = (value) => {
  return !notAssigned(value);
};

export const assignedWithDefault = (value, defaultValue, ignoreValues = []) => {
  if (notAssigned(value) || value === 0 || ignoreValues.includes(value)) {
    return defaultValue;
  }
  return value;
};

export const extractDates = (text) => {
  const datePattern = /\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2}/;
  const matches = text.match(datePattern);

  if (matches) {
    return matches[0].replace('_', ' / ');
  }

  return text;
};

export const calculateSpaceOccupied = (data1, data2, totalOccupie) => {
  const total = data1 + data2;
  const data1Ratio = data1 / total;
  const data2Ratio = data2 / total;

  const data1Space = data1Ratio * totalOccupie;
  const data2Space = data2Ratio * totalOccupie;

  return {
    data1Space: data1Space,
    data2Space: data2Space,
  };
};

export const camelCaseToTitle = (camelCaseString) => {
  let result = camelCaseString.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const dateFormatter = (date, format = 'MM/DD/YYYY') => {
  return moment(date).format(format);
};

export const validateText = (text, minLength, fieldName) => {
  // İsim boş olmamalı
  if (text.toString().trim() === '') {
    return { error: true, message: `${fieldName} is required.` };
  }

  // İsim belirli bir uzunluk aralığında olmalı (örneğin en az 3 karakter)
  if (text.length < minLength) {
    return { error: true, message: `${fieldName} must be min 3 characters.` };
  }

  // İsim kural hatası yoksa, başarılı olarak işaretlenir
  return { error: false, message: '' };
};

export const validateEmail = (email) => {
  if (email.toString().trim() === '') {
    return { error: true, message: 'E-mail is required.' };
  }

  // E-posta adresi geçerli bir formatta olmalı
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailRegex.test(email)) {
    return { error: true, message: 'Enter a valid e-mail address.' };
  }

  // E-posta adresi kural hatası yoksa, başarılı olarak işaretlenir
  return { error: false, message: '' };
};

export const getTimeZoneOffsetByDate = (timeZone, date = new Date()) => {
  return moment(date).tz(timeZone).utcOffset();
};

export const toDateTimeString = (
  value,
  dateJoinChar = '-',
  timeJoinChar = ':',
  concatChar = ' '
) => {
  if (notAssigned(value)) {
    return '';
  }

  // If it is not date object, try to conver it into Date
  let date = value instanceof Date ? value : new Date(value);

  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let year = date.getFullYear().toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();
  let second = date.getSeconds().toString();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return (
    [year, month, day].join(dateJoinChar) +
    concatChar +
    [hour, minute, second].join(timeJoinChar)
  );
};

export const parseDateTime = (value) => {
  if (notAssigned(value) || value == '') {
    return null;
  }
  let convertedDate = new Date(value);
  if (convertedDate == 'Invalid Date') return null;

  return convertedDate;
};

export const parseDate = (value, localTimezone = 'UTC') => {
  if (notAssigned(value) || value == '') {
    return null;
  }

  // Following dates are different.
  // To fix it, we are using a temp value
  // new Date('2022-07-01')
  // new Date('2022-7-1')
  let tempValue = toDateString(value);

  let date = new Date(tempValue);
  let dateInMs = date.getTime();
  let sourceTimezoneOffset = getTimeZoneOffsetByDate(localTimezone, date);
  let timezoneDiffInMs = sourceTimezoneOffset * 60 * 1000;

  return new Date(dateInMs - timezoneDiffInMs);
};

export const parseDateTimeWithTimezone = (value, localTimezone = 'UTC') => {
  if (notAssigned(value) || value == '') {
    return null;
  }

  // Following dates are different.
  // To fix it, we are using a temp value
  // new Date('2022-07-01')
  // new Date('2022-7-1')
  let tempValue = toDateTimeString(value);

  let date = new Date(tempValue);
  let dateInMs = date.getTime();
  let sourceTimezoneOffset = getTimeZoneOffsetByDate(localTimezone, date);
  let timezoneDiffInMs = sourceTimezoneOffset * 60 * 1000;

  return new Date(dateInMs - timezoneDiffInMs);
};

export const currencyToNumber = (suffix, value, isDecimal = false) => {
  if (!value || typeof value !== 'string') return;
  const replacedValue = parseFloat(value.replace(suffix, ''));

  if (!isDecimal) {
    return parseInt(replacedValue, 10);
  } else {
    return parseFloat(replacedValue).toFixed(2);
  }
};

export const camelToSnakeCase = (str) => {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();
};

export function urlify(content) {
  if (!content) {
    return '';
  }

  const urlRegex = /(\b(https?:\/\/|www\.))[^\s]+/g;

  return content.replace(urlRegex, (url) => {
    // Ensure the URL starts with http:// or https://
    let hyperlink = url.startsWith('http') ? url : `http://${url}`;

    return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer" style="color: #0099ff; text-decoration: underline;">${url}</a>`;
  });
}

export const backToPageForCRM = (router, route) => {
  const { accountId, tableName, secondTableName } = route.params;

  if (secondTableName) {
    router.push({
      name: 'admin-dashboard/details',
      params: {
        tableName: tableName,
        accountId: accountId,
      },
    });
  } else {
    router.push({
      name: 'admin-dashboard/pages',
      params: {
        page: `${tableName}-list`,
      },
    });
  }
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const convertTimezone = (date) => {
  const correctDate = date.toString().replace('Z', '').replace('T', ' ');

  return moment(correctDate).toDate();
};
